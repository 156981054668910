import {
  People as PeopleIcon,
  Payments as PaymentsIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  Shop2 as ShopsIcon,
  Timeline as TimelineIcon,
  Assignment as AssignmentIcon,
  Analytics as AnalyticsIcon,
  Groups as GroupsIcon,
  CreditCard as CreditCardIcon,
  BarChart as BarChartIcon,
  AccountBalance as AccountBalanceIcon,
  MonetizationOnOutlined as MonetizationOnOutlinedIcon,
  Report as ReportIcon,
  AutoMode as AutoModeIcon,
  Abc as AbcIcon,
  MenuBook as MenuBookIcon,
  Archive as ArchiveIcon,
  Webhook as WebhookIcon,
  QueryStats as QueryStatsIcon,
  CompareArrows as CompareArrowsIcon,
  Receipt as ReceiptIcon,
  RequestQuote as RequestQuoteIcon,
  Route as RouteIcon,
} from '@mui/icons-material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LayoutMenuSection } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { TranslationNamespace } from 'i18n';
import { UserLayout } from 'layouts/User';

type Props = {
  children: React.ReactElement;
};

export const TechOperatorLayout: React.FC<Props> = ({ children }: Props) => {
  const { t } = useTranslation(TranslationNamespace.TechOperator, {
    keyPrefix: 'layout',
  });

  const menuSections: LayoutMenuSection[] = useMemo(
    () => [
      {
        title: t('menu_sections.cash_flow'),
        items: [
          {
            title: t('menu_items.assets'),
            MuiIcon: AnalyticsIcon,
            link: ROUTE_PATH.TECH_OPERATOR.MAIN,
          },
          {
            title: t('menu_items.funds_requests'),
            MuiIcon: PaymentsIcon,
            link: ROUTE_PATH.TECH_OPERATOR.FUNDS_REQUESTS,
          },
          {
            title: t('menu_items.internal_transfers'),
            MuiIcon: CompareArrowsIcon,
            link: ROUTE_PATH.TECH_OPERATOR.INTERNAL_TRANSFERS,
          },
          {
            title: t('menu_items.orders'),
            MuiIcon: AssignmentIcon,
            link: ROUTE_PATH.TECH_OPERATOR.PAYIN_ORDERS,
          },
        ],
      },
      {
        title: t('menu_sections.commissions'),
        items: [
          {
            title: t('menu_items.traders'),
            MuiIcon: BarChartIcon,
            link: ROUTE_PATH.TECH_OPERATOR.TRADERS,
          },
          {
            title: t('menu_items.shops'),
            MuiIcon: ShopsIcon,
            link: ROUTE_PATH.TECH_OPERATOR.SHOPS,
          },
          {
            title: t('menu_items.cashback'),
            MuiIcon: RequestQuoteIcon,
            link: ROUTE_PATH.TECH_OPERATOR.CASHBACK,
          },
        ],
      },
      {
        title: t('menu_sections.platform'),
        items: [
          {
            title: t('menu_items.transactions'),
            MuiIcon: ReceiptIcon,
            link: ROUTE_PATH.TECH_OPERATOR.TRANSACTIONS,
          },
          {
            title: t('menu_items.groups'),
            MuiIcon: GroupsIcon,
            link: ROUTE_PATH.TECH_OPERATOR.GROUPS,
          },
          {
            title: t('menu_items.users'),
            MuiIcon: PeopleIcon,
            link: ROUTE_PATH.TECH_OPERATOR.USERS,
          },
          {
            title: t('menu_items.currencies'),
            MuiIcon: MonetizationOnOutlinedIcon,
            link: ROUTE_PATH.TECH_OPERATOR.CURRENCIES,
          },
          {
            title: t('menu_items.banks'),
            MuiIcon: AccountBalanceIcon,
            link: ROUTE_PATH.TECH_OPERATOR.BANKS,
          },
          {
            title: t('menu_items.trade_methods'),
            MuiIcon: CurrencyExchangeIcon,
            link: ROUTE_PATH.TECH_OPERATOR.TRADE_METHODS,
          },
          {
            title: t('menu_items.order_automation'),
            MuiIcon: AutoModeIcon,
            link: ROUTE_PATH.TECH_OPERATOR.ORDER_AUTOMATION,
          },
          {
            title: t('menu_items.automation_sources'),
            MuiIcon: AbcIcon,
            link: ROUTE_PATH.TECH_OPERATOR.AUTOMATION_SOURCES,
          },
          {
            title: t('menu_items.archive'),
            MuiIcon: ArchiveIcon,
            link: ROUTE_PATH.TECH_OPERATOR.ARCHIVE,
          },
          {
            title: t('menu_items.webhooks'),
            MuiIcon: WebhookIcon,
            link: ROUTE_PATH.TECH_OPERATOR.WEBHOOKS,
          },
          {
            title: t('menu_items.sci_domains'),
            MuiIcon: WebhookIcon,
            link: ROUTE_PATH.TECH_OPERATOR.SCI_DOMAINS,
          },
          {
            title: t('menu_items.p2p_providers'),
            MuiIcon: RouteIcon,
            link: ROUTE_PATH.TECH_OPERATOR.P2P_PROVIDERS,
          },
        ],
      },
      {
        title: t('menu_sections.analytics'),
        items: [
          {
            title: t('menu_items.requisites'),
            MuiIcon: CreditCardIcon,
            link: ROUTE_PATH.TECH_OPERATOR.REQUISITES,
          },
          {
            title: t('menu_items.asset_balance_history'),
            MuiIcon: TimelineIcon,
            link: ROUTE_PATH.TECH_OPERATOR.ASSET_BALANCE_HISTORY,
          },
          {
            title: t('menu_items.anomalies'),
            MuiIcon: ReportIcon,
            link: ROUTE_PATH.TECH_OPERATOR.ANOMALIES,
          },
          {
            title: t('menu_items.statistics'),
            MuiIcon: QueryStatsIcon,
            link: ROUTE_PATH.TECH_OPERATOR.STATISTICS,
          },
          {
            title: t('menu_items.audit'),
            MuiIcon: MenuBookIcon,
            link: ROUTE_PATH.TECH_OPERATOR.AUDIT,
          },
        ],
      },
    ],
    [t],
  );

  return <UserLayout menuSections={menuSections} children={children} />;
};
