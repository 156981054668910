import { Button, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { map } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { currencyExchangeApi } from 'api';
import {
  DataWrapper,
  FormControls,
  FormikNumericField,
  FormikSelect,
  FormikTextField,
  FormikYesNoRadioGroup,
  PageHeader,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { CurrencyExchangeSource, QueryKey } from 'enums';
import {
  useCrossFiatCurrencyExchange,
  useCurrencies,
  useMutation,
  useUser,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { CurrencyExchange } from 'types';
import { currencyExchangeUtils, validationUtils } from 'utils';

type Values = Pick<
  CurrencyExchange,
  | 'assetCurrencyId'
  | 'fiatCurrencyId'
  | 'source'
  | 'enabled'
  | 'refresh'
  | 'defaultExchange'
  | 'name'
> &
  Partial<
    Pick<
      CurrencyExchange,
      | 'price'
      | 'binancePayTypes'
      | 'bybitPayment'
      | 'deviationPercentage'
      | 'amount'
      | 'advOrderCount'
      | 'deviationFiatCurrencyExchangeId'
      | 'fluctuationMinutes'
      | 'fluctuationPercentage'
    >
  >;

export const CurrencyExchangeDetailsPage: React.FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isAdmin, isTechOperator } = useUser();

  // TODO: move tKeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.currency_exchange_details',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const { fiatCurrenciesOptions, assetCurrenciesOptions } = useCurrencies();
  const { crossFiatCurrencyExchangeOptions } = useCrossFiatCurrencyExchange();

  const isNew = useMemo(() => id === NEW_ID, [id]);

  const [initialValues, setInitialValues] = useState<Values>({
    assetCurrencyId: '',
    fiatCurrencyId: '',
    name: '',
    price: 0,
    source: CurrencyExchangeSource.Binance,
    enabled: true,
    refresh: true,
    defaultExchange: false,
    binancePayTypes: '',
    bybitPayment: '',
    deviationPercentage: 0,
    amount: null,
    advOrderCount: null,
    deviationFiatCurrencyExchangeId: '',
    fluctuationMinutes: 0,
    fluctuationPercentage: 0,
  });

  const queryResult = useQuery<CurrencyExchange, AxiosError>(
    [QueryKey, id],
    () => currencyExchangeApi.getOne(id as string),
    {
      enabled: !isNew,
      onSuccess: (item) => {
        setInitialValues({
          assetCurrencyId: item.assetCurrencyId,
          fiatCurrencyId: item.fiatCurrencyId,
          name: item.name,
          price: item.price,
          source: item.source,
          enabled: item.enabled,
          refresh: item.refresh,
          defaultExchange: item.defaultExchange,
          binancePayTypes: item.binancePayTypes || '',
          bybitPayment: item.bybitPayment || '',
          deviationPercentage: item.deviationPercentage,
          amount: item.amount,
          advOrderCount: item.advOrderCount,
          deviationFiatCurrencyExchangeId:
            item.deviationFiatCurrencyExchangeId || '',
          fluctuationMinutes: item.fluctuationMinutes,
          fluctuationPercentage: item.fluctuationPercentage,
        });
      },
    },
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        assetCurrencyId: Yup.string().required(tCommon('errors.required')),
        fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        name: Yup.string().required(tCommon('errors.required')),
        source: Yup.mixed<CurrencyExchangeSource>()
          .oneOf(Object.values(CurrencyExchangeSource))
          .required(tCommon('errors.required')),
        price: Yup.number().when('refresh', {
          is: false,
          then: (schema) =>
            schema
              .moreThan(0, tCommon('errors.natural_number'))
              .required(tCommon('errors.required')),
        }),
        binancePayTypes: Yup.string(),
        bybitPayment: Yup.string(),
        enabled: Yup.boolean().required(tCommon('errors.required')),
        refresh: Yup.boolean().required(tCommon('errors.required')),
        defaultExchange: Yup.boolean().required(tCommon('errors.required')),
        deviationPercentage: Yup.number()
          .moreThan(-100, tCommon('errors.percentage_absolute'))
          .lessThan(100, tCommon('errors.percentage_absolute')),
        amount: Yup.number().optional().nullable(),
        advOrderCount: Yup.number().optional().nullable(),
        deviationFiatCurrencyExchangeId: Yup.string().optional(),
        fluctuationMinutes: Yup.number().optional().nullable(),
        fluctuationPercentage: Yup.number().optional().nullable(),
      }),
    [tCommon],
  );
  const { mutate: createCurrency } = useMutation<
    CurrencyExchange,
    AxiosError,
    Partial<CurrencyExchange>,
    unknown
  >(currencyExchangeApi.create);

  const { mutate: updateCurrency } = useMutation<
    CurrencyExchange,
    AxiosError,
    { id: string; data: Partial<CurrencyExchange> },
    unknown
  >(currencyExchangeApi.update);

  const sourceOptions = useMemo(
    () =>
      map(CurrencyExchangeSource, (status) => ({
        value: status,
        label: currencyExchangeUtils.getCurrencyExchangeSourceLabel(status),
      })),
    [],
  );

  const backUrl = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.CURRENCIES;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.CURRENCIES;
    }
  }, [isAdmin, isTechOperator]);

  const onSubmit = useCallback(
    (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) => {
      const data = {
        ...values,
        amount: values.amount || null,
        advOrderCount: values.advOrderCount || null,
      };
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.CurrencyExchangeRates);
          navigate(backUrl!);
        },
        onSettled: () => {
          setSubmitting(false);
        },
        onError: (error: AxiosError) => {
          setErrors(validationUtils.getFormErrors(error));
        },
      };
      if (isNew) {
        createCurrency(data, options);
      } else {
        updateCurrency({ id, data }, options);
      }
    },
    [isNew, queryClient, backUrl, navigate, createCurrency, updateCurrency, id],
  );

  const handleSubmit = useCallback(
    (values: Values, helpers: FormikHelpers<Values>) => {
      onSubmit(values, helpers);
    },
    [onSubmit],
  );

  return (
    <Fragment>
      <PageHeader title={t('title')}></PageHeader>
      <DataWrapper queryResult={isNew ? undefined : queryResult}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <div className="tw-max-w-xs">
                <FormControls>
                  <FormikYesNoRadioGroup
                    label={t('fields.enabled')}
                    name="enabled"
                  />
                  <FormikTextField label={t('fields.name')} name="name" />
                  <FormikSelect
                    label={t('fields.source')}
                    name="source"
                    options={sourceOptions}
                  />
                  {formik.values.source === CurrencyExchangeSource.Binance && (
                    <FormikTextField
                      label={t('fields.payment')}
                      name="binancePayTypes"
                      helperText={t('fields.payment_helper')}
                    />
                  )}
                  {formik.values.source === CurrencyExchangeSource.Bybit && (
                    <FormikTextField
                      label={t('fields.payment')}
                      name="bybitPayment"
                      helperText={t('fields.payment_helper')}
                    />
                  )}
                  <FormikSelect
                    label={t('fields.asset_currency')}
                    name="assetCurrencyId"
                    options={assetCurrenciesOptions}
                    disabled={!isNew}
                  />
                  <FormikSelect
                    label={t('fields.fiat_currency')}
                    name="fiatCurrencyId"
                    options={fiatCurrenciesOptions}
                    disabled={!isNew}
                  />
                  <FormikNumericField
                    name="deviationPercentage"
                    defaultValue={0}
                    label={t('fields.deviation_percentage')}
                    percentageSuffix
                  />
                  <FormikSelect
                    label={t('fields.deviation_fiat_currency_exchange')}
                    name="deviationFiatCurrencyExchangeId"
                    options={crossFiatCurrencyExchangeOptions}
                    helperText={t(
                      'fields.deviation_fiat_currency_exchange_description',
                    )}
                    noneOption
                  />
                  <FormikNumericField
                    label={t('fields.amount')}
                    name="amount"
                    allowNegative={false}
                  />
                  <FormikNumericField
                    label={t('fields.adv_order_count')}
                    name="advOrderCount"
                    allowNegative={false}
                  />
                  <FormikYesNoRadioGroup
                    label={t('fields.refresh')}
                    name="refresh"
                  />
                  <FormikNumericField
                    label={t('fields.price')}
                    name="price"
                    allowNegative={false}
                    required
                    disabled={formik.values.refresh}
                  />
                  <FormikYesNoRadioGroup
                    label={t('fields.default_exchange')}
                    name="defaultExchange"
                  />
                  <div>
                    <Typography variant="h6">
                      {t('fields.fluctuation')}
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                      {t('fields.fluctuation_helper')}
                    </Typography>
                  </div>
                  <FormikNumericField
                    label={t('fields.fluctuation_percentage')}
                    name="fluctuationPercentage"
                    allowNegative={false}
                  />
                  <FormikNumericField
                    label={t('fields.fluctuation_minutes')}
                    name="fluctuationMinutes"
                    decimalScale={0}
                    allowNegative={false}
                  />
                </FormControls>
                <div className="tw-mt-4">
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {tCommon('buttons.save')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </DataWrapper>
    </Fragment>
  );
};
