import { Timeline as TimelineIcon } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { map, split, trim } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { currencyExchangeApi } from 'api';
import {
  CloseDialogResult,
  DataGrid,
  DataGridColumnDefinition,
  DataWrapper,
  Dialog,
  dataGridColumns,
} from 'components';
import { ERROR_MESSAGE } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey, StatusCode } from 'enums';
import { useCurrencies, useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { CurrencyExchange } from 'types';
import { currencyExchangeUtils, formatUtils } from 'utils';

import { CurrencyExchangePriceChartDialog } from './CurrencyExchangePriceChartDialog';

export const CurrenciesExchange: React.FC = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.currencies_exchange',
  });
  const { t: tCommon } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isAdmin, isTechOperator } = useUser();

  const { getAssetCurrencyCode, getFiatCurrencyCode } = useCurrencies();

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: CurrencyExchange;
  }>({
    open: false,
  });

  const [priceChartDialogProps, setPriceCharthDialogProps] = useState<{
    open: boolean;
    data?: CurrencyExchange;
  }>({
    open: false,
  });

  const queryResult = useQuery(
    QueryKey.CurrencyExchange,
    () => currencyExchangeApi.getAll(),
    {},
  );

  const { mutate: removeCurrency, isLoading: isRemoveLoading } = useMutation(
    currencyExchangeApi.remove,
    {
      onSuccess: () => {
        setConfirmRemoveDialogProps({ open: false });
        queryClient.invalidateQueries(QueryKey.CurrencyExchange);
        queryClient.invalidateQueries(QueryKey.CurrencyExchangeRates);
      },
      notifierType: 'remove',
      notifierMessages: {
        error: (error: AxiosError<{ message: string | undefined }>) => {
          const status = error?.response?.status;
          if (
            status === StatusCode.Conflict &&
            error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
          ) {
            return tCommon('errors.in_use');
          }
        },
      },
    },
  );

  const handleRemove = useCallback((data: CurrencyExchange) => {
    setConfirmRemoveDialogProps({ open: true, data });
  }, []);

  const handleCloseRemoveDialog = useCallback(
    ({ ok, data }: CloseDialogResult<CurrencyExchange>) => {
      if (ok && data) {
        removeCurrency(data.id);
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [removeCurrency],
  );

  const detailsPath = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.CURRENCY_EXCHANGE_DETAILS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.CURRENCY_EXCHANGE_DETAILS;
    }
  }, [isAdmin, isTechOperator]);

  const handleOpenDetails = useCallback(
    ({ id }: { id: string }) => {
      navigate(generatePath(detailsPath!, { id }));
    },
    [detailsPath, navigate],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<CurrencyExchange>[] => [
      {
        header: t('fields.name'),
        valueKey: 'name',
      },
      {
        header: t('fields.source'),
        valueGetter: (item) => {
          const payment = item.binancePayTypes || item.bybitPayment;
          return (
            <div>
              {currencyExchangeUtils.getCurrencyExchangeSourceLabel(
                item.source,
              )}
              {payment && (
                <div className="tw-text-xs tw-text-neutral-400">
                  {map(split(payment, ','), (p, i) => (
                    <div key={i}>{trim(p)}</div>
                  ))}
                </div>
              )}
            </div>
          );
        },
      },
      {
        header: t('fields.currency'),
        valueGetter: (item) =>
          `${getAssetCurrencyCode(
            item.assetCurrencyId,
          )} / ${getFiatCurrencyCode(item.fiatCurrencyId)}`,
      },
      {
        header: t('fields.price'),
        valueGetter: (item) => (
          <div>
            <div>{formatUtils.formatMoney(item.price)}</div>
            <Typography variant="caption" color="textSecondary">
              {!!item.deviationPercentage && (
                <div>{`${t('fields.deviation')} ${formatUtils.formatNumber(
                  item.deviationPercentage,
                  { sign: true },
                )}%`}</div>
              )}
              {formatUtils.formatDate(item.date)}
            </Typography>
          </div>
        ),
      },
      {
        header: t('fields.enabled'),
        valueKey: 'enabled',
        valueFormatter: formatUtils.formatBoolean,
      },
      {
        header: t('fields.refresh'),
        valueKey: 'refresh',
        valueFormatter: formatUtils.formatBoolean,
      },
      {
        header: t('fields.params'),
        valueGetter: (item) => (
          <div className="tw-grid tw-gap-1 tw-text-xs">
            {item.amount && (
              <div>
                {`${t('fields.amount')}: `} {item.amount}
              </div>
            )}
            {item.advOrderCount && (
              <div>
                {`${t('fields.adv_order_count')}: `} {item.advOrderCount}
              </div>
            )}
            {item.defaultExchange && <div>{t('fields.default_exchange')}</div>}
            {(!!item.fluctuationMinutes || !!item.fluctuationPercentage) && (
              <div>
                <div>{t('fields.fluctuation')}</div>
                <div>{`${t('fields.fluctuation_minutes')}: ${
                  item.fluctuationMinutes
                }`}</div>
                <div>{`${t('fields.fluctuation_percentage')}: ${
                  item.fluctuationPercentage
                }`}</div>
                {!!item.fluctuationCount && (
                  <div>{`${t('fields.fluctuation_count')}: ${
                    item.fluctuationCount
                  }`}</div>
                )}
              </div>
            )}
          </div>
        ),
      },
      dataGridColumns.getActionsColumn({
        children: (item) => (
          <IconButton
            color="primary"
            onClick={() => {
              setPriceCharthDialogProps({ open: true, data: item });
            }}
          >
            <TimelineIcon />
          </IconButton>
        ),
        handleEdit: handleOpenDetails,
        handleRemove,
      }),
    ],
    [
      t,
      getAssetCurrencyCode,
      getFiatCurrencyCode,
      handleOpenDetails,
      handleRemove,
    ],
  );

  return (
    <Fragment>
      <DataWrapper queryResult={queryResult}>
        <DataGrid columns={columns} data={queryResult.data}></DataGrid>
      </DataWrapper>
      <CurrencyExchangePriceChartDialog
        {...priceChartDialogProps}
        onClose={() => setPriceCharthDialogProps({ open: false })}
      />
      <Dialog
        title={t('remove_dialog.title')}
        onClose={handleCloseRemoveDialog}
        disabled={isRemoveLoading}
        {...confirmRemoveDialogProps}
      >
        <Fragment>
          {confirmRemoveDialogProps.data && (
            <div>
              <div className="tw-mb-2">{t('remove_dialog.description')}</div>
              <div>{t('remove_dialog.subdescription')}</div>
              <div className="tw-font-bold tw-mt-4">
                {getAssetCurrencyCode(
                  confirmRemoveDialogProps.data.assetCurrencyId,
                )}
                {' - '}
                {getFiatCurrencyCode(
                  confirmRemoveDialogProps.data.fiatCurrencyId,
                )}
              </div>
            </div>
          )}
        </Fragment>
      </Dialog>
    </Fragment>
  );
};
