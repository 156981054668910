import { Block as BlockIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmButton, RequisitesStatusToggle } from 'components';
import { RequisitesStatus, RequisitesStatusDetails } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { formatUtils } from 'utils';

type BlockProps = {
  statusDetails?: RequisitesStatusDetails;
  blockedAt?: string;
  onClick?: MouseEventHandler<any>;
};

const RequisitesStatusBlock: React.FC<BlockProps> = ({
  statusDetails,
  blockedAt,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx('tw-flex tw-items-center tw-p-3', {
        'tw-cursor-pointer': !!onClick,
      })}
      onClick={onClick}
    >
      <Tooltip
        title={
          <div>
            <div>
              {`${t('requisites_status.blocked')}: ${t(
                `requisites_status_details.${
                  statusDetails as RequisitesStatusDetails
                }`,
              )}`}
            </div>
            <div>{`${t('common.date')}: ${formatUtils.formatDate(
              blockedAt,
            )}`}</div>
          </div>
        }
      >
        <BlockIcon color="error" />
      </Tooltip>
    </div>
  );
};

type Props = {
  initialStatus: RequisitesStatus;
  statusDetails?: RequisitesStatusDetails;
  blockedAt?: string;
  readOnly: boolean;
  updateStatus: (status: RequisitesStatus) => void;
  unblock?: () => void;
};

export const RequisitesStatusInfo: React.FC<Props> = ({
  initialStatus,
  statusDetails,
  blockedAt,
  readOnly,
  updateStatus,
  unblock,
}) => {
  const { isManager } = useUser();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.unblock_dialog',
  });

  if (initialStatus === RequisitesStatus.Blocked) {
    if (isManager && unblock) {
      return (
        <ConfirmButton onConfirm={unblock} dialog={{ children: t('content') }}>
          {({ onClick }) => (
            <RequisitesStatusBlock
              statusDetails={statusDetails}
              blockedAt={blockedAt}
              onClick={onClick}
            />
          )}
        </ConfirmButton>
      );
    }
    return (
      <RequisitesStatusBlock
        statusDetails={statusDetails}
        blockedAt={blockedAt}
      />
    );
  }

  return (
    <RequisitesStatusToggle
      initialStatus={initialStatus}
      updateStatus={updateStatus}
      readOnly={readOnly}
    />
  );
};
