import { Box, Divider } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyText, DescriptionRow, Money, OrderStatusLabel } from 'components';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';
import { orderUtils } from 'utils';

import { P2PProviderWebhooks } from '../P2PProviderWebhooks';

type Props = {
  order: PayinOrder;
};

export const P2PProviderOrderDetails: React.FC<Props> = ({ order }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.details',
  });

  const {
    externalOrderId,
    status,
    statusDetails,
    amount,
    assetCurrencyAmount,
    requisites,
    currencyRate,
  } = useMemo(() => order.p2pProviderOrder || ({} as any), [order]);

  if (!order.p2pProviderOrder) {
    return null;
  }

  return (
    <Fragment>
      <Divider textAlign="left">
        {t('p2p_provider_order_details.title')}
      </Divider>
      {order.p2pProviderOrder?.provider && (
        <DescriptionRow
          title={t('p2p_provider_order_details.provider')}
          value={
            <Fragment>
              <CopyText text={order.p2pProviderOrder.provider.id} />
              <div>{order.p2pProviderOrder.provider.name}</div>
            </Fragment>
          }
        />
      )}
      <DescriptionRow
        title={t('p2p_provider_order_details.id')}
        value={externalOrderId}
        canCopy
      />
      {status && (
        <DescriptionRow
          title={t('p2p_provider_order_details.status')}
          value={
            <Fragment>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <OrderStatusLabel status={status} />
              </Box>
              {statusDetails && (
                <div>{orderUtils.getStatusDetailsLabel(statusDetails)}</div>
              )}
            </Fragment>
          }
        />
      )}
      {requisites && (
        <DescriptionRow
          title={t('p2p_provider_order_details.requisites')}
          value={orderUtils.renderP2PProviderOrderRequisites(order)}
        />
      )}
      {amount && assetCurrencyAmount && currencyRate && (
        <DescriptionRow
          title={t('p2p_provider_order_details.amount')}
          value={
            <Fragment>
              <Money
                value={amount}
                fiatCurrencyId={order.fiatCurrencyId}
                symbol
              />
              <div>
                <Money
                  value={assetCurrencyAmount}
                  assetCurrencyId={order.assetCurrencyId}
                  symbol
                />
                {' ('}
                <Money
                  value={currencyRate}
                  fiatCurrencyId={order.fiatCurrencyId}
                  symbol
                />
                {')'}
              </div>
            </Fragment>
          }
        />
      )}
      <P2PProviderWebhooks order={order.p2pProviderOrder} />
    </Fragment>
  );
};
