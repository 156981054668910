import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { find, some } from 'lodash';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { payoutRequisitesApi, tradersApi } from 'api';
import {
  DataGridColumnDefinition,
  dataGridColumns,
  CloseDialogResult,
  Dialog,
  RequisitesInfo,
  RequisitesStatusInfo,
  CrudPage,
} from 'components';
import { ERROR_MESSAGE, NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import {
  FilterDefinitionType,
  QueryKey,
  RequisitesStatus,
  StatusCode,
} from 'enums';
import {
  useCurrencies,
  useMutation,
  usePartialQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, PayoutRequisites } from 'types';
import { formatUtils, formUtils, requisitesUtils } from 'utils';

type RequisitesFilters = Partial<{
  traderId: string;
  status: RequisitesStatus;
  bankId: string;
  paymentTypeId: string;
  fiatCurrencyId: string;
}>;

const REQUISITES_DETAILS = ROUTE_PATH.TRADER.PAYOUT_REQUISITES_DETAILS;

type Props = {
  title: string;
};

export const PayoutRequisitesPage: FC<Props> = ({ title }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const queryClient = useQueryClient();

  const { role, isTrader, isManager } = useUser();
  const { tradeMethods, banks, paymentTypes } = useUserContext();

  const navigate = useNavigate();

  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: PayoutRequisites;
  }>({ open: false });

  const queryResult = usePartialQuery(
    QueryKey.PayoutRequisites,
    payoutRequisitesApi.getAllAsRole(role),
  );

  const queryResultTraders = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    {
      enabled: isManager,
    },
  );

  const { mutate: remove, isLoading } = useMutation(
    payoutRequisitesApi.remove,
    {
      onSuccess: () => {
        setConfirmRemoveDialogProps({ open: false });
        queryClient.invalidateQueries(QueryKey.PayoutRequisites);
      },
      notifierType: 'remove',
      notifierMessages: {
        error: (error: AxiosError<{ message: string | undefined }>) => {
          const status = error?.response?.status;
          if (
            status === StatusCode.Conflict &&
            error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
          ) {
            return t('remove_dialog.errors.in_use');
          }
        },
      },
    },
  );

  const { mutate: updateStatus } = useMutation<
    PayoutRequisites,
    AxiosError,
    { id: string; data: Pick<PayoutRequisites, 'status'> }
  >(payoutRequisitesApi.updateStatus, {
    onError: () => {
      queryClient.invalidateQueries(QueryKey.PayoutRequisites);
    },
  });

  const { mutate: unblock } = useMutation<PayoutRequisites, AxiosError, string>(
    payoutRequisitesApi.unblock,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.PayoutRequisites);
      },
      notifierType: 'execute',
    },
  );

  const handleStatusChange = useCallback(
    (item: PayoutRequisites, status: RequisitesStatus) => {
      updateStatus({
        id: item.id,
        data: { status },
      });
    },
    [updateStatus],
  );

  const handleOpenDetails = useCallback(
    (requisitesId: string) => {
      const url = generatePath(REQUISITES_DETAILS, {
        id: requisitesId,
      });
      navigate(url);
    },
    [navigate],
  );

  const handleCreate = useCallback(
    () => handleOpenDetails(NEW_ID),
    [handleOpenDetails],
  );

  const handleRemove = useCallback((item: PayoutRequisites) => {
    setConfirmRemoveDialogProps({ open: true, data: item });
  }, []);

  const handleCloseRemoveDialog = useCallback(
    (result: CloseDialogResult<PayoutRequisites>) => {
      if (result.ok && result.data) {
        remove(result.data.id);
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [remove],
  );

  const isTradeMethodSupported = useCallback(
    (requisites: PayoutRequisites) =>
      some(tradeMethods, {
        fiatCurrencyId: requisites.fiatCurrencyId,
        paymentTypeId: requisites.paymentTypeId,
      }),
    [tradeMethods],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<PayoutRequisites>[] => [
      {
        header: t('table.columns.status'),
        valueGetter: (item) => (
          <div>
            <RequisitesStatusInfo
              initialStatus={item.status}
              statusDetails={item.statusDetails}
              blockedAt={item.blockedAt}
              updateStatus={(status: RequisitesStatus) =>
                handleStatusChange(item, status)
              }
              unblock={() => unblock(item.id)}
              readOnly={!isTrader}
            />
            {!isTradeMethodSupported(item) && (
              <Tooltip
                title={tCommon(
                  'features.requisites.error.trade_method_unavailable',
                )}
              >
                <WarningAmberIcon color="warning" />
              </Tooltip>
            )}
          </div>
        ),
      },
      dataGridColumns.getIdColumn(),
      {
        header: t('table.columns.trader_name'),
        valueGetter: (item) => item.user?.name,
        hidden: !isManager,
      },
      {
        header: t('table.columns.requisites'),
        valueGetter: (item) => (
          <RequisitesInfo
            paymentTypeId={item.paymentTypeId}
            bankId={item.bankId}
            fiatCurrencyId={item.fiatCurrencyId}
          />
        ),
      },
      {
        header: t('table.columns.transaction_sum'),
        valueGetter: (item) => (
          <Fragment>
            {!!item.minTransactionSum && (
              <span>{`${tCommon('common.from')} ${formatUtils.formatMoney(
                item.minTransactionSum,
              )} `}</span>
            )}
            {!!item.maxTransactionSum && (
              <span>{`${tCommon('common.to')} ${formatUtils.formatMoney(
                item.maxTransactionSum,
              )}`}</span>
            )}
          </Fragment>
        ),
      },
      {
        header: t('table.columns.sum'),
        valueFormatter: formatUtils.formatMoney,
        valueGetter: (item) => formatUtils.formatMoney(item.limits.limitSum),
      },
      {
        header: t('table.columns.count'),
        valueGetter: (item) => formatUtils.formatMoney(item.limits.limitCount),
      },
      ...(isTrader
        ? [
            dataGridColumns.getActionsColumn({
              handleEdit: (item: PayoutRequisites) =>
                handleOpenDetails(item.id),
              handleRemove,
            }),
          ]
        : []),
    ],
    [
      t,
      isTrader,
      isManager,
      handleRemove,
      tCommon,
      isTradeMethodSupported,
      handleOpenDetails,
      handleStatusChange,
      unblock,
    ],
  );

  const filtersDefinitions: FilterDefinition<RequisitesFilters>[] = useMemo(
    () => [
      {
        label: t('filters.trader'),
        name: 'traderId',
        type: FilterDefinitionType.Trader,
        traders: queryResultTraders.data,
        getDisplayName: (traderId: string) =>
          find(queryResultTraders.data, { id: traderId })?.user?.name,
        hidden: !isManager,
      },
      {
        label: t('filters.status'),
        name: 'status',
        type: FilterDefinitionType.Enum,
        enum: RequisitesStatus,
        getDisplayName: requisitesUtils.getStatusLabel,
      },
      {
        label: t('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (bankId: string) => find(banks, { id: bankId })?.name,
      },
      {
        label: t('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (paymentType: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: paymentType })!,
          ),
      },
      {
        label: t('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
    ],
    [
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      isManager,
      paymentTypes,
      queryResultTraders.data,
      t,
    ],
  );

  return (
    <Fragment>
      <CrudPage
        header={{
          title,
          ...(isTrader && {
            rightContentButton: { onClick: handleCreate },
          }),
        }}
        filters={{ filtersDefinitions }}
        table={{ queryResult, columns, paginated: true }}
      />
      <Dialog
        title={t('remove_dialog.title')}
        disabled={isLoading}
        onClose={handleCloseRemoveDialog}
        {...confirmRemoveDialogProps}
      />
    </Fragment>
  );
};
