import { Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { assetsApi } from 'api';
import { AssetsTable, TotalBalance } from 'components';
import { AssetsTabs, QueryKey } from 'enums';
import { PlatformFundsRequestDialog } from 'features';
import { useCalculateAssetBalances } from 'hooks';
import { Asset } from 'types';

export const PlatformBalance: React.FC = () => {
  const queryClient = useQueryClient();
  const queryResult = useQuery(
    QueryKey.PlatformAssets,
    assetsApi.getAllPlatform,
  );

  const { balances } = useCalculateAssetBalances(queryResult.data || []);

  const [fundsRequestDialogProps, setFundsRequestDialogProps] = useState<{
    open: boolean;
    asset?: Asset;
  }>({ open: false });

  const handleFundsRequestDialogClose = useCallback(() => {
    setFundsRequestDialogProps({
      open: false,
    });
    queryClient.invalidateQueries(QueryKey.PlatformAssets);
  }, [queryClient]);

  return (
    <Stack spacing={4}>
      <TotalBalance balances={balances} />

      <AssetsTable
        tab={AssetsTabs.PlatformBalance}
        queryResult={queryResult}
        onFundsRequest={(asset) =>
          setFundsRequestDialogProps({
            open: true,
            asset,
          })
        }
      />

      <PlatformFundsRequestDialog
        {...fundsRequestDialogProps}
        onClose={handleFundsRequestDialogClose}
      />
    </Stack>
  );
};
