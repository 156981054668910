import { createContext } from 'react';

import {
  Bank,
  Currency,
  PaymentType,
  User,
  TradeMethod,
  Meta,
  CurrencyExchange,
  SciDomain,
  P2PProvider,
} from 'types';

export type UserContextProps = {
  user: User;
  fiatCurrencies: Currency[];
  assetCurrencies: Currency[];
  banks: Bank[];
  paymentTypes: PaymentType[];
  tradeMethods: TradeMethod[];
  currencyExchanges: CurrencyExchange[];
  meta: Meta[];
  sciDomains: SciDomain[];
  p2pProviders: P2PProvider[];
};

export const UserContext = createContext<UserContextProps>(
  {} as UserContextProps,
);
