import {
  Divider,
  Stack,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { Field, Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { RadioGroup, TextField } from 'formik-mui';
import { filter, find, isEmpty, map, omit, some, sortBy } from 'lodash';
import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { shopsApi, usersApi } from 'api';
import {
  AGENTS_VALIDATION_SCHEMA,
  AgentsFormFields,
  DataWrapper,
  FormActions,
  FormControls,
  FormikCheckbox,
  FormikNumericField,
  FormikSelect,
  FormikSelectOption,
  PageHeader,
} from 'components';
import { IP_REGEX, NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import {
  CustomerDataCollectionOrder,
  OrderStatus,
  PayoutOrderStatus,
  QueryKey,
  ShopStatus,
} from 'enums';
import {
  useCrossFiatCurrencyExchange,
  useCurrencies,
  useMutation,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Shop, ShopPaymentTypeConfig } from 'types';
import { formUtils, orderUtils, shopUtils, validationUtils } from 'utils';

import { CustomerFieldsPayin } from './CustomerFieldsPayin';
import { ShopP2PProviders } from './ShopP2PProviders';
import { ShopPayinGroups } from './ShopPayinGroups';
import { ShopPayinTradeMethods } from './ShopPayinTradeMethods';
import { ShopPayoutTradeMethods } from './ShopPayoutTradeMethods';
import { ShopStatusSelect } from '../ShopStatusSelect';

export type Values = Pick<
  Shop,
  | 'name'
  | 'webSiteUrl'
  | 'webSiteIp'
  | 'status'
  | 'commission'
  | 'payoutCommission'
  | 'assetCurrencyId'
  | 'fiatCurrencyId'
  | 'payinCurrencyExchangeId'
  | 'payinFiatCurrencyExchangeId'
  | 'payinFiatCurrencyExchangeMinSpread'
  | 'payinFiatCurrencyExchangeMaxSpread'
  | 'payoutCurrencyExchangeId'
  | 'agents'
  | 'configs'
  | 'payinOrderMinAmount'
  | 'payinOrderMaxAmount'
  | 'payoutOrderMinAmount'
  | 'payoutOrderMaxAmount'
  | 'customerDataCollectionOrder'
  | 'collectCustomerReceipts'
  | 'payinTimeoutConfig'
  | 'payoutTimeoutConfig'
  | 'payinTraderSpreadCompensation'
  | 'payinTradeMethodsConfigs'
  | 'payoutTradeMethodsConfigs'
  | 'p2pProvidersConfigs'
  | 'payinGroupsConfigs'
  | 'sciDomainId'
>;

export const ManageShop: React.FC = () => {
  const { id: shopId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { paymentTypes, sciDomains } = useUserContext();
  const sciDomainOptions = formUtils.getOptions(sciDomains, 'url', 'id');

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.manage',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const {
    assetCurrenciesOptions,
    fiatCurrenciesOptions,
    currencyExchanges,
    getFiatCurrencyCode,
    getCurrencyExchangeOptions,
    getDefaultCurrencyExchange,
  } = useCurrencies();
  const [currencyExchangeOptions, setCurrencyExchangeOptions] = useState<
    FormikSelectOption[]
  >([]);
  const [mappedFiatCurrenciesOptions, setMappedFiatCurrenciesOptions] =
    useState<FormikSelectOption[]>([]);

  const isNew = useMemo(() => shopId === NEW_ID, [shopId]);
  const title = useMemo(
    () => t(isNew ? 'new_shop.title' : 'edit_shop.title'),
    [isNew, t],
  );

  const {
    user,
    role,
    isAdmin,
    isTechOperator,
    isMerchant,
    isAdminOrTechOperator,
  } = useUser();

  const backUrl = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.SHOPS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.SHOPS;
    } else if (isMerchant) {
      return ROUTE_PATH.MERCHANT.SHOPS;
    }
  }, [isAdmin, isTechOperator, isMerchant]);

  const initialState: Values = useMemo(
    () => ({
      name: '',
      webSiteUrl: '',
      webSiteIp: '',
      commission: user?.transactionPercentage || 0,
      payoutCommission: user?.payoutTransactionPercentage || 0,
      assetCurrencyId: assetCurrenciesOptions[0]?.value || '',
      fiatCurrencyId:
        filter(fiatCurrenciesOptions, (option) =>
          some(currencyExchanges, {
            assetCurrencyId: assetCurrenciesOptions[0]?.value || '',
            fiatCurrencyId: option.value,
          }),
        )?.[0]?.value || '',
      payinCurrencyExchangeId: '',
      payoutCurrencyExchangeId: '',
      payinFiatCurrencyExchangeId: '',
      configs: [],
      payinOrderMinAmount: 0,
      payinOrderMaxAmount: 0,
      payoutOrderMinAmount: 0,
      payoutOrderMaxAmount: 0,
      collectCustomerReceipts: false,
      status: ShopStatus.Inactive, //todo: move to admin view as well and fix tests
      ...(isAdminOrTechOperator && {
        commission: user?.transactionPercentage || 0,
        agents: [],
        customerDataCollectionOrder: CustomerDataCollectionOrder.BeforePayment,
        payinFiatCurrencyExchangeMinSpread: 0,
        payinFiatCurrencyExchangeMaxSpread: 0,
        payinTraderSpreadCompensation: false,
        sciDomainId: '',
      }),
      payinTradeMethodsConfigs: [],
      payoutTradeMethodsConfigs: [],
      p2pProvidersConfigs: [],
      payinGroupsConfigs: [],
    }),
    [
      user,
      assetCurrenciesOptions,
      isAdminOrTechOperator,
      fiatCurrenciesOptions,
      currencyExchanges,
    ],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(tCommon('errors.required')),
        webSiteUrl: Yup.string().required(tCommon('errors.required')),
        webSiteIp: Yup.string().matches(IP_REGEX, tCommon('errors.ip')),
        assetCurrencyId: Yup.string().required(tCommon('errors.required')),
        fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        payinCurrencyExchangeId: Yup.string().nullable(),
        payoutCurrencyExchangeId: Yup.string().nullable(),
        payinFiatCurrencyExchangeId: Yup.string().nullable(),
        payinFiatCurrencyExchangeMinSpread: Yup.number()
          .moreThan(-100, tCommon('errors.percentage'))
          .lessThan(100, tCommon('errors.percentage'))
          .nullable(),
        payinFiatCurrencyExchangeMaxSpread: Yup.number()
          .moreThan(-100, tCommon('errors.percentage'))
          .lessThan(100, tCommon('errors.percentage'))
          .nullable(),
        commission: Yup.number()
          .min(0, tCommon('errors.percentage'))
          .max(100, tCommon('errors.percentage')),
        payoutCommission: Yup.number()
          .min(0, tCommon('errors.percentage'))
          .max(100, tCommon('errors.percentage')),
        configs: Yup.array().of(
          Yup.object().shape({
            paymentTypeId: Yup.string().required(tCommon('errors.required')),
          }),
        ),
        ...(isAdminOrTechOperator && {
          payinTraderSpreadCompensation: Yup.boolean(),
          payinTradeMethodsConfigs: Yup.array().of(
            Yup.object().shape({
              tradeMethodId: Yup.string().required(tCommon('errors.required')),
            }),
          ),
          payoutTradeMethodsConfigs: Yup.array().of(
            Yup.object().shape({
              tradeMethodId: Yup.string().required(tCommon('errors.required')),
            }),
          ),
          p2pProvidersConfigs: Yup.array().of(
            Yup.object().shape({
              p2pProviderId: Yup.string().required(tCommon('errors.required')),
            }),
          ),
          payinGroupsConfigs: Yup.array().of(
            Yup.object().shape({
              groupId: Yup.string().required(tCommon('errors.required')),
            }),
          ),
          ...AGENTS_VALIDATION_SCHEMA,
        }),
      }),
    [tCommon, isAdminOrTechOperator],
  );

  const [initialValues, setInitialValues] = useState<Values>(initialState);

  const normalizeConfigs = useCallback(
    (configs: ShopPaymentTypeConfig[]) =>
      map(configs, (config) => {
        const customerFields = find(paymentTypes, {
          id: config.paymentTypeId,
        })?.customerFields;

        config.customerFields = sortBy(
          map(
            customerFields,
            (field) =>
              find(config.customerFields, { name: field.name }) || {
                name: field.name,
                hidden: field.hidden,
              },
          ),
          'hidden',
        );
        return config;
      }),
    [paymentTypes],
  );

  const getSortedPayinGroupsConfigs = useCallback((shop: Shop) => {
    const payinGroups = filter(shop.groups, (group) => group.payin);
    const groupsWithPriority = payinGroups.map((group) => {
      const config = find(
        shop.payinGroupsConfigs,
        (config) => config.groupId === group.id,
      );

      return {
        id: config?.id,
        groupId: group.id,
        priority: config?.priority || 0,
      };
    });
    return sortBy(groupsWithPriority, (group) => -group.priority);
  }, []);

  const queryClient = useQueryClient();
  const queryResult = useQuery(
    [QueryKey.Shops, shopId],
    () => shopsApi.findOneAsRole(role)(shopId),
    {
      enabled: !!shopId && !isNew,
      onSuccess: (data) => {
        setInitialValues({
          name: data.name,
          webSiteUrl: data.webSiteUrl,
          webSiteIp: data.webSiteIp,
          assetCurrencyId: data.assetCurrencyId,
          fiatCurrencyId: data.fiatCurrencyId,
          payinCurrencyExchangeId: data.payinCurrencyExchangeId || '',
          payoutCurrencyExchangeId: data.payoutCurrencyExchangeId || '',
          payinFiatCurrencyExchangeId: data.payinFiatCurrencyExchangeId || '',
          payinFiatCurrencyExchangeMinSpread:
            data.payinFiatCurrencyExchangeMinSpread,
          payinFiatCurrencyExchangeMaxSpread:
            data.payinFiatCurrencyExchangeMaxSpread,
          commission: data.commission,
          payoutCommission: data.payoutCommission,
          status: data.status,
          configs: normalizeConfigs(data.configs),
          ...(isAdminOrTechOperator && {
            agents: data.agents,
            customerDataCollectionOrder: data.customerDataCollectionOrder,
            payinTraderSpreadCompensation: data.payinTraderSpreadCompensation,
            sciDomainId: data.sciDomainId,
          }),
          payinOrderMinAmount: data.payinOrderMinAmount,
          payinOrderMaxAmount: data.payinOrderMaxAmount,
          payoutOrderMinAmount: data.payoutOrderMinAmount,
          payoutOrderMaxAmount: data.payoutOrderMaxAmount,
          collectCustomerReceipts: data.collectCustomerReceipts,
          payinTimeoutConfig: data.payinTimeoutConfig,
          payoutTimeoutConfig: data.payoutTimeoutConfig,
          payinTradeMethodsConfigs: data.payinTradeMethodsConfigs,
          payoutTradeMethodsConfigs: data.payoutTradeMethodsConfigs,
          p2pProvidersConfigs: sortBy(data.p2pProvidersConfigs, 'priority'),
          payinGroupsConfigs: getSortedPayinGroupsConfigs(data),
        });
      },
      keepPreviousData: true,
    },
  );

  const payinGroups = useMemo(
    () => filter(queryResult.data?.groups || [], (group) => group.payin),
    [queryResult],
  );

  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
    { enabled: isAdminOrTechOperator },
  );

  const { mutate: createShop } = useMutation<Shop, AxiosError, Partial<Shop>>(
    shopsApi.create,
  );
  const { mutate: updateShop } = useMutation<
    Shop,
    AxiosError,
    { id: string; shop: Values }
  >(shopsApi.updateAsRole(role));

  const handleSubmit = useCallback(
    (shop: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.Shops);
          navigate(backUrl!);
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);

      if (!isAdminOrTechOperator) {
        shop = omit(
          shop,
          'payinOrderMinAmount',
          'payinOrderMaxAmount',
          'payoutOrderMinAmount',
          'payoutOrderMaxAmount',
          'payinTimeoutConfig',
          'payoutTimeoutConfig',
          'payinFiatCurrencyExchangeMinSpread',
          'payinFiatCurrencyExchangeMaxSpread',
          'payinTraderSpreadCompensation',
          'payinTradeMethodsConfigs',
          'payoutTradeMethodsConfigs',
          'p2pProvidersConfigs',
          'payinGroupsConfigs',
          'sciDomainId',
        );
        if (!isNew) {
          shop = omit(shop, 'fiatCurrencyId');
        }
      }
      if (isNew) {
        createShop(shop, options);
      } else {
        updateShop({ id: shopId, shop }, options);
      }
    },
    [
      isNew,
      queryClient,
      navigate,
      backUrl,
      createShop,
      updateShop,
      isAdminOrTechOperator,
      shopId,
    ],
  );

  const handleCancel = useCallback(() => {
    navigate(backUrl!);
  }, [backUrl, navigate]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const { crossFiatCurrencyExchangeOptions, crossFiatCurrencyExchange } =
    useCrossFiatCurrencyExchange({
      enabled: isAdminOrTechOperator,
      fiatCurrencyId: formik.values.fiatCurrencyId,
    });

  const shopFiatCurrencyCode = useMemo(
    () => getFiatCurrencyCode(formik.values.fiatCurrencyId),
    [formik.values.fiatCurrencyId, getFiatCurrencyCode],
  );

  useEffect(() => {
    const assetCurrencyId = isNew
      ? initialState.assetCurrencyId
      : queryResult.data?.assetCurrencyId;
    const fiatCurrencyId = isNew
      ? initialState.fiatCurrencyId
      : queryResult.data?.fiatCurrencyId;
    const shouldUpdate = assetCurrencyId && fiatCurrencyId;

    if (shouldUpdate) {
      setCurrencyExchangeOptions(
        getCurrencyExchangeOptions({
          assetCurrencyId,
          fiatCurrencyId,
        }),
      );
    }
  }, [
    isNew,
    initialState.assetCurrencyId,
    initialState.fiatCurrencyId,
    queryResult.data?.assetCurrencyId,
    queryResult.data?.fiatCurrencyId,
    getCurrencyExchangeOptions,
  ]);

  useEffect(() => {
    if (queryResult.data?.assetCurrencyId && queryResult.data?.fiatCurrencyId) {
      setCurrencyExchangeOptions(
        getCurrencyExchangeOptions({
          assetCurrencyId: queryResult.data.assetCurrencyId,
          fiatCurrencyId: queryResult.data.fiatCurrencyId,
        }),
      );
    }
  }, [
    queryResult.data?.assetCurrencyId,
    queryResult.data?.fiatCurrencyId,
    getCurrencyExchangeOptions,
  ]);

  const isCurrencyExchangeSelectDisabled = useMemo(
    () => currencyExchangeOptions.length === 1,
    [currencyExchangeOptions],
  );

  useEffect(() => {
    const assetCurrencyId = isNew
      ? initialState.assetCurrencyId
      : queryResult.data?.assetCurrencyId;
    const shouldUpdate = !!assetCurrencyId;
    if (shouldUpdate) {
      setMappedFiatCurrenciesOptions(
        filter(fiatCurrenciesOptions, (option) =>
          some(currencyExchanges, {
            assetCurrencyId,
            fiatCurrencyId: option.value,
          }),
        ),
      );
    }
  }, [
    isNew,
    initialState.assetCurrencyId,
    queryResult.data?.assetCurrencyId,
    fiatCurrenciesOptions,
    currencyExchanges,
  ]);

  const isFiatSelectDisabled = useMemo(
    () =>
      mappedFiatCurrenciesOptions.length === 1 ||
      (!isAdminOrTechOperator && !isNew),
    [mappedFiatCurrenciesOptions, isAdminOrTechOperator, isNew],
  );

  const handleCurrencyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      formik.handleChange(e);

      const { value, name } = e.target;
      const isAssetCurrencyChanged = name === 'assetCurrencyId';
      const assetCurrencyId = isAssetCurrencyChanged
        ? value
        : formik.values.assetCurrencyId;
      const isFiatCurrencyChanged = name === 'fiatCurrencyId';
      let fiatCurrencyId = isFiatCurrencyChanged
        ? value
        : formik.values.fiatCurrencyId;

      if (isAssetCurrencyChanged) {
        const newMappedFiatCurrenciesOptions = filter(
          fiatCurrenciesOptions,
          (option) =>
            some(currencyExchanges, {
              assetCurrencyId,
              fiatCurrencyId: option.value,
            }),
        );

        if (assetCurrencyId !== formik.initialValues.assetCurrencyId) {
          if (
            !find(
              newMappedFiatCurrenciesOptions,
              ({ value }) => value === fiatCurrencyId,
            )
          ) {
            fiatCurrencyId = newMappedFiatCurrenciesOptions[0].value;
            formik.setFieldValue('fiatCurrencyId', fiatCurrencyId);
          }
        } else {
          fiatCurrencyId = formik.initialValues.fiatCurrencyId;
          formik.setFieldValue('fiatCurrencyId', fiatCurrencyId);
        }

        setMappedFiatCurrenciesOptions(newMappedFiatCurrenciesOptions);
      }

      if (assetCurrencyId && fiatCurrencyId) {
        setCurrencyExchangeOptions(
          getCurrencyExchangeOptions({
            assetCurrencyId,
            fiatCurrencyId,
          }),
        );
      }

      if (isFiatCurrencyChanged) {
        formik.setFieldValue('payinFiatCurrencyExchangeId', '');
        formik.setFieldValue('payinTradeMethodsConfigs', []);
        formik.setFieldValue('payoutTradeMethodsConfigs', []);
        formik.setFieldValue('p2pProvidersConfigs', []);
      }

      if (
        assetCurrencyId !== formik.initialValues.assetCurrencyId ||
        fiatCurrencyId !== formik.initialValues.fiatCurrencyId
      ) {
        // if merchant modify currency - drop selected exchange
        // if admin modify currency - set default exchange
        const defaultCurrencyExchangeId = isAdminOrTechOperator
          ? getDefaultCurrencyExchange(assetCurrencyId, fiatCurrencyId)?.id ||
            ''
          : '';

        formik.setFieldValue(
          'payinCurrencyExchangeId',
          defaultCurrencyExchangeId,
        );
        formik.setFieldValue(
          'payoutCurrencyExchangeId',
          defaultCurrencyExchangeId,
        );
      } else {
        formik.setFieldValue(
          'payinCurrencyExchangeId',
          formik.initialValues.payinCurrencyExchangeId,
        );
        formik.setFieldValue(
          'payoutCurrencyExchangeId',
          formik.initialValues.payoutCurrencyExchangeId,
        );
      }
    },
    [
      formik,
      isAdminOrTechOperator,
      currencyExchanges,
      fiatCurrenciesOptions,
      getCurrencyExchangeOptions,
      getDefaultCurrencyExchange,
    ],
  );

  const renderTimeoutInput = useCallback(
    (prefix: 'payin' | 'payout', status: OrderStatus | PayoutOrderStatus) => (
      <FormikNumericField
        name={`${prefix}TimeoutConfig.${status}`}
        label={orderUtils.getStatusLabel(status)}
        allowNegative={false}
        decimalScale={0}
        disabled={!isAdminOrTechOperator}
      />
    ),
    [isAdminOrTechOperator],
  );

  return (
    <Fragment>
      <PageHeader title={title} />
      <FormikProvider value={formik}>
        <DataWrapper queryResult={!isNew ? queryResult : undefined}>
          <div className="tw-max-w-lg">
            <Form>
              <Grid container spacing={6}>
                <Grid item xs={12} md={12}>
                  <FormControls>
                    <Typography variant="subtitle1">
                      {t('sections.common')}
                    </Typography>
                    {isAdminOrTechOperator && (
                      <Fragment>
                        <ShopStatusSelect />
                        <Stack spacing={3}>
                          <FormikNumericField
                            label={t('fields.commission')}
                            name="commission"
                            allowNegative={false}
                            percentageSuffix
                            required
                            fullWidth
                          />
                          <FormikNumericField
                            label={t('fields.payout_commission')}
                            name="payoutCommission"
                            allowNegative={false}
                            percentageSuffix
                            required
                            fullWidth
                          />
                        </Stack>
                        <Divider />
                      </Fragment>
                    )}
                    <Field
                      component={TextField}
                      label={t('fields.name')}
                      name="name"
                      size="small"
                      required
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      label={t('fields.website_url')}
                      name="webSiteUrl"
                      size="small"
                      required
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      label={t('fields.website_ip')}
                      name="webSiteIp"
                      size="small"
                      fullWidth
                    />
                    <FormikSelect
                      label={t('fields.currency')}
                      name="assetCurrencyId"
                      options={assetCurrenciesOptions}
                      onChange={handleCurrencyChange}
                      showMetaError
                      disabled={!isNew}
                    />
                    <FormikSelect
                      label={t('fields.fiat_currency')}
                      name="fiatCurrencyId"
                      options={mappedFiatCurrenciesOptions}
                      onChange={handleCurrencyChange}
                      disabled={isFiatSelectDisabled}
                    />
                    {!isEmpty(currencyExchangeOptions) &&
                      isAdminOrTechOperator && (
                        <Fragment>
                          <FormikSelect
                            label={t('fields.payin_currency_exchange')}
                            name="payinCurrencyExchangeId"
                            options={currencyExchangeOptions}
                            noneOption
                            disabled={isCurrencyExchangeSelectDisabled}
                          />
                          <FormikSelect
                            label={t('fields.payout_currency_exchange')}
                            name="payoutCurrencyExchangeId"
                            options={currencyExchangeOptions}
                            noneOption
                            disabled={isCurrencyExchangeSelectDisabled}
                          />
                        </Fragment>
                      )}
                    {isAdminOrTechOperator && (
                      <FormikSelect
                        label={t('fields.sci_domain')}
                        name="sciDomainId"
                        options={sciDomainOptions}
                        noneOption
                      />
                    )}
                    {isAdminOrTechOperator && (
                      <Fragment>
                        <FormikSelect
                          label={t('fields.payin_fiat_currency_exchange')}
                          name="payinFiatCurrencyExchangeId"
                          options={crossFiatCurrencyExchangeOptions}
                          noneOption
                          disabled={isEmpty(crossFiatCurrencyExchangeOptions)}
                        />
                        <Divider>
                          {t('fields.payin_fiat_currency_exchange_spread')}
                        </Divider>
                        <FormControls row>
                          <FormikNumericField
                            label={t('fields.min')}
                            name="payinFiatCurrencyExchangeMinSpread"
                            fullWidth
                            percentageSuffix
                          />
                          <FormikNumericField
                            label={t('fields.max')}
                            name="payinFiatCurrencyExchangeMaxSpread"
                            fullWidth
                            percentageSuffix
                          />
                        </FormControls>
                        <FormikCheckbox
                          name="payinTraderSpreadCompensation"
                          label={t('fields.payin_trader_spread_compensation')}
                        />
                      </Fragment>
                    )}
                    {isAdminOrTechOperator && (
                      <AgentsFormFields
                        formError={formik.errors['agents']}
                        agents={formik.values.agents}
                        users={queryResultTraders.data}
                        headerClassName="tw-text-base"
                      />
                    )}
                    {!isNew && (
                      <Fragment>
                        <Divider>
                          {t('sections.payin_order_amount_limits')}
                        </Divider>
                        <FormControls row>
                          <FormikNumericField
                            name="payinOrderMinAmount"
                            label={tCommon('common.min')}
                            fullWidth
                            disabled={!isAdminOrTechOperator}
                            suffix={shopFiatCurrencyCode}
                          />
                          <FormikNumericField
                            name="payinOrderMaxAmount"
                            label={tCommon('common.max')}
                            fullWidth
                            disabled={!isAdminOrTechOperator}
                            suffix={shopFiatCurrencyCode}
                          />
                        </FormControls>
                        <Divider>
                          {t('sections.payout_order_amount_limits')}
                        </Divider>
                        <FormControls row>
                          <FormikNumericField
                            name="payoutOrderMinAmount"
                            label={tCommon('common.min')}
                            fullWidth
                            disabled={!isAdminOrTechOperator}
                            suffix={shopFiatCurrencyCode}
                          />
                          <FormikNumericField
                            name="payoutOrderMaxAmount"
                            label={tCommon('common.max')}
                            fullWidth
                            disabled={!isAdminOrTechOperator}
                            suffix={shopFiatCurrencyCode}
                          />
                        </FormControls>
                      </Fragment>
                    )}
                  </FormControls>
                  {!isNew && (
                    <Fragment>
                      <Divider sx={{ my: 4 }}>
                        {t('fields.timeouts_config')}
                      </Divider>
                      <Grid container spacing={6}>
                        <Grid item xs={6} md={6}>
                          <FormControls>
                            <Typography>{tCommon('common.payin')}</Typography>
                            {renderTimeoutInput('payin', OrderStatus.New)}
                            {renderTimeoutInput(
                              'payin',
                              OrderStatus.Requisites,
                            )}
                            {renderTimeoutInput(
                              'payin',
                              OrderStatus.CustomerConfirm,
                            )}
                            {renderTimeoutInput(
                              'payin',
                              OrderStatus.TraderConfirm,
                            )}
                          </FormControls>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <FormControls>
                            <Typography>{tCommon('common.payout')}</Typography>
                            {renderTimeoutInput(
                              'payout',
                              PayoutOrderStatus.Requisites,
                            )}
                            {renderTimeoutInput(
                              'payout',
                              PayoutOrderStatus.TraderAccept,
                            )}
                            {renderTimeoutInput(
                              'payout',
                              PayoutOrderStatus.TraderPayment,
                            )}
                          </FormControls>
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
                {isAdminOrTechOperator && (
                  <Fragment>
                    <Grid item xs={12} md={12}>
                      <FormControls>
                        <ShopPayinTradeMethods
                          fiatCurrencyId={formik.values.fiatCurrencyId}
                          crossFiatCurrencyId={
                            find(crossFiatCurrencyExchange, {
                              id: formik.values.payinFiatCurrencyExchangeId,
                            })?.crossFiatCurrencyId
                          }
                        />
                      </FormControls>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControls>
                        <ShopPayoutTradeMethods
                          fiatCurrencyId={formik.values.fiatCurrencyId}
                        />
                      </FormControls>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControls>
                        <ShopP2PProviders
                          assetCurrencyId={formik.values.assetCurrencyId}
                          tradeMethodsIds={map(
                            formik.values.payinTradeMethodsConfigs,
                            (tradeMethod) => tradeMethod.tradeMethodId,
                          )}
                        />
                      </FormControls>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControls>
                        <ShopPayinGroups groups={payinGroups} />
                      </FormControls>
                    </Grid>
                  </Fragment>
                )}
                <Grid item xs={12} md={12}>
                  <FormControls>
                    <CustomerFieldsPayin paymentTypes={paymentTypes} />

                    <Divider />

                    <FormControl>
                      <FormikCheckbox
                        name="collectCustomerReceipts"
                        label={t('fields.collect_customer_receipts')}
                      />
                    </FormControl>

                    {isAdminOrTechOperator && (
                      <FormControl>
                        <FormLabel>
                          {t('fields.customer_data_collection_order')}
                        </FormLabel>
                        <Field
                          component={RadioGroup}
                          name="customerDataCollectionOrder"
                          row
                        >
                          {Object.values(CustomerDataCollectionOrder).map(
                            (order) => (
                              <FormControlLabel
                                key={order}
                                value={order}
                                control={<Radio />}
                                label={shopUtils.getCustomerDataCollectionOrder(
                                  order,
                                )}
                              />
                            ),
                          )}
                        </Field>
                      </FormControl>
                    )}
                  </FormControls>
                </Grid>
              </Grid>
              <FormActions>
                <Button variant="outlined" onClick={handleCancel}>
                  {tCommon('buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={formik.submitForm}
                >
                  {tCommon('buttons.save')}
                </Button>
              </FormActions>
            </Form>
          </div>
        </DataWrapper>
      </FormikProvider>
    </Fragment>
  );
};
